import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <ul>
                <li><a href='https://licensemanagerui.grandline.dev/'>License-Manager UI</a></li>
                <li><a href='https://eibdeskui.grandline.dev/'>EIB-Desk UI</a></li>
                <li><a href='https://timegapfinderui.grandline.dev/'>Time Gap Finder</a></li>

                
        </ul>
      </div>
    );
  }
}
